'use client'

import { Button, ErrorBadge, Heading } from '@repo/ui'
import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import SiteHeader from '~/app/(site)/components/SiteHeader'
import { CopyErrorId } from '~/components/CopyErrorId'
import configuration from '~/configuration'
import Trans from '~/core/ui/Trans'
import I18nProvider from '~/i18n/I18nProvider'

export const metadata = {
  title: `An error occurred - ${configuration.site.name}`,
}

const ErrorPage = ({ error }: { error: Error & { digest?: string } }) => {
  const [errorId, setErrorId] = useState<string | null>(null)
  useEffect(() => {
    console.error(error)
    setErrorId(Sentry.captureException(error))
  }, [error])

  return (
    <I18nProvider>
      <SiteHeader />

      <div className="m-auto flex min-h-[50vh] w-full items-center justify-center">
        <div className={'flex flex-col'}>
          <div className={'flex justify-center mb-5'}>
            <ErrorBadge statusCode={'500'} />
          </div>

          <div className={'flex flex-col space-y-2 justify-center mb-10'}>
            <div>
              <Heading size="large" className="text-center">
                <Trans i18nKey={'common:genericError'} />
              </Heading>
            </div>

            <p className={'text-gray-500 dark:text-gray-300 text-center'}>
              <Trans i18nKey={'common:genericErrorSubHeading'} />
            </p>
          </div>

          <div className={'flex space-x-4 justify-center'}>
            <Link href={'/'}>
              <Button variant={'outline'} icon="ChatBubbleOvalLeftEllipsis">
                <Trans i18nKey={'common:contactUs'} />
              </Button>
            </Link>

            <Link href={'/'}>
              <Button>
                <Trans i18nKey={'common:backToHomePage'} />
              </Button>
            </Link>
          </div>
          {errorId && <CopyErrorId errorId={errorId} />}
        </div>
      </div>
    </I18nProvider>
  )
}

export default ErrorPage
