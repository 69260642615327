'use client'
import { Icon, useToast } from '@repo/ui'
import clsx from 'clsx'
import { useMemo, useState } from 'react'

export function CopyErrorId({ errorId }: { errorId: string }) {
  const [copied, setCopied] = useState(false)
  const { toast } = useToast()
  const copyValue = useMemo(() => {
    const url = window.location.href
    return [`Error ID: ${errorId}`, `URL: ${url}`].join('\n')
  }, [errorId])

  const onCopy = () => {
    setCopied(true)
    toast({
      title: 'Copied to clipboard',
      variant: 'default',
      color: 'success',
    })
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(copyValue)
    onCopy()
  }

  if (!errorId) return null
  return (
    <div
      className={clsx(
        'flex group space-x-2 -ml-2 flex-row items-center w-full justify-center',
        'pt-8 font-mono text-xs cursor-pointer',
        'opacity-25 hover:opacity-100 hover:underline',
      )}
      title="Click to Copy"
    >
      {copied ? (
        <Icon name="Check" className="text-green-500" size="small" />
      ) : (
        <Icon
          name="Clipboard"
          className="invisible group-hover:visible"
          size="small"
        />
      )}
      <div onClick={copyToClipboard}>Error ID: {errorId}</div>
    </div>
  )
}
